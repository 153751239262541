import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FaCheck, FaFileDownload, FaRegEye, FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TableColumnPopover from '../../app/common/popover/TableColumnPopover';
import StyleCellTable from '../../app/common/table/StyleCellTable';
import ViewRequestAttachment from '../../app/common/request-attachment/ViewRequestAttachment';
import PaginatedTable from '../../app/common/table/PaginatedTable';
import DateHelper from '../../app/helpers/dateHelper';
import { FilterValues } from '../../app/models/common/filterValues';
import { LeaveRequest } from '../../app/models/leaveRequest';
import { useStore } from '../../app/stores/store';
import { history } from '../../index';
import LeaveRequestExportModal from './LeaveRequestExportModal';


const dateHelper = new DateHelper();
const swal = withReactContent(Swal);

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledColumn = styled(Grid.Column)`
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

interface RequestAttachment {
    attachmentId: string | null;
    attachmentType: string | null;
    attachmentName: string | null;
}

interface DownloadParameters {
    id: string;
    name: string
    type: string
}

const LeaveRequestList = () => {
    const { leaveRequestStore, mediaStore } = useStore();
    const { downloadAttachment } = mediaStore;
    const { filterLeaveRequests, isLeaveRequestsLoading, leaveRequests,
        leaveRequestsTotal, approveLeaveRequest, rejectLeaveRequest } = leaveRequestStore;

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get("search");
    const page = params.get("page");
    const refresh = params.get("refresh");

    const [attachment, setAttachment] = useState<RequestAttachment | null>();
    const [modalState, setmodalState] = useState(false);
    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 20,
        pageNumber: Number(page) !== 0 ? Number(page) : 1,
        searchKeyword: search ?? ""
    });

    const handleOnChangePage = (page: number) => {
        params.set('page', page.toString());

        history.push({
            search: params.toString(),
        });

        setFilterValues({ ...filterValues, pageNumber: page });
    }

    const getTotalDays = (from: string | Date, to: string | Date, ishalfDay: boolean | string) => {
        let totalDays = dateHelper.getDaysDifference(from, to) + 1;

        if (ishalfDay == true && totalDays == 1)
            totalDays -= 0.5;

        return totalDays
    }

    const handleSearch = (searchText: string) => {
        params.set('search', searchText);
        params.set('page', "1");
        params.set('refresh', (Math.random() + 1).toString(36).substring(7));

        history.push({
            search: params.toString(),
        });

        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText.trim() });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText.trim() });
        }
    }

    const handleOpenDownloadModal = (props) => {
        setmodalState(true);
    }


    const handleDownloadAttachment = ({ id, name, type }: DownloadParameters) => {
        if ((!type.includes('video') && !type.includes('image'))) {

            downloadAttachment(id, name);
        } else {
            setAttachment({ attachmentId: id, attachmentType: type, attachmentName: name })
        }
    }

    const handleCloseAttachment = () => {
        setAttachment(null);
    }

    useEffect(() => {
        setFilterValues({ ...filterValues, searchKeyword: search ?? "", pageNumber: Number(page) !== 0 ? Number(page) : 1 });
        filterLeaveRequests({ ...filterValues, searchKeyword: search ?? "", pageNumber: Number(page) !== 0 ? Number(page) : 1 });
    }, [search, page, refresh]);

    return (
        <>
            {attachment && <ViewRequestAttachment
                onPreviewClose={handleCloseAttachment}
                attachmentId={attachment.attachmentId!}
                attachmentType={attachment.attachmentType!}
                attachmentName={attachment.attachmentName!}
            />}
            <StyledContainer>
                <StyledHeader>
                    <Grid>
                        <Grid.Column width={8}>
                            Leave Requests
                        </Grid.Column>
                        <StyledColumn width={8}>
                            <Button onClick={handleOpenDownloadModal} icon='file excel outline' color='green' content='Export Report' />
                        </StyledColumn>
                    </Grid>
                </StyledHeader>
                <PaginatedTable<LeaveRequest>
                    columns={[
                        { title: 'Employee', field: 'user.name' },
                        { title: 'Leave Type', field: 'leaveType.type' },
                        { title: 'From', render: (data: LeaveRequest) => dateHelper.formatShortDate(data.from.toString()) },
                        { title: 'To', render: (data: LeaveRequest) => dateHelper.formatShortDate(data.to.toString()) },
                        {
                            title: 'Total Days',
                            render: (data: LeaveRequest) => (
                                <StyleCellTable
                                    tooltip={data.hasDayOff ? 'A rest day falls on the leave range' : null}
                                    content={getTotalDays(data.from, data.to, data.halfDay).toString()}
                                    color={data.hasDayOff ? '#dc3545' : 'inherit'} />
                            ),
                        },
                        {
                            title: 'Reason',
                            render: (data: LeaveRequest) => (

                                <TableColumnPopover width={150} content={data.reason} />
                            )
                        },
                        {
                            title: 'HR Approval',
                            render: (data: LeaveRequest) => (
                                data.rejectReason ?
                                    <TableColumnPopover columnContent={data.leaveRequestStatus.name} content={data.rejectReason} />
                                    : data.leaveRequestStatus.name
                            )
                        },
                        {
                            title: 'TL Approval',
                            field: 'teamLeadApprovalStatus.name'
                        },
                        {
                            title: 'Status',
                            field: 'clientApprovalStatus.name'
                        },
                        {
                            title: 'Leave Balance',
                            field: 'leaveBalance'
                        },
                    ]}
                    isLoading={isLeaveRequestsLoading}
                    data={leaveRequests!}
                    total={leaveRequestsTotal}
                    onChangePage={handleOnChangePage}
                    pageSize={filterValues.pageSize}
                    pageNumber={filterValues.pageNumber}
                    onSearchChange={handleSearch}
                    toolbar={true}
                    search={true}
                    sorting={false}
                    draggable={false}
                    actions={[rowData => ({
                        icon: () => <FaFileDownload style={{ color: '#21ba45', fontSize: '16px' }} />,
                        tooltip: rowData.file != null ? rowData.file.name : '',
                        onClick: e => {
                            handleDownloadAttachment(rowData.file);
                        },
                        hidden: !rowData.file,
                    }),
                    rowData => ({
                        icon: () => <FaCheck style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Approve',
                        onClick: e => {
                            swal.fire({
                                title: 'Approval Confirmation',
                                text: "Are you sure you want to approve this leave request?",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    approveLeaveRequest(rowData.id, filterValues);
                                }
                            });
                        },
                        hidden: rowData.clientApprovalStatus.name != 'Pending',
                    }),
                    rowData => ({
                        icon: () => <FaTimes style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Reject',
                        onClick: e => {
                            swal.fire({
                                title: 'Rejection Confirmation',
                                text: "Are you sure you want to reject this leave request?",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    rejectLeaveRequest(rowData.id, filterValues);
                                }
                            });
                        },
                        hidden: rowData.clientApprovalStatus.name != 'Pending',
                    }),
                    rowData => ({
                        icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                        tooltip: 'View Leave Request',
                        onClick: e => {
                            history.push(`/requests/leave-requests/${rowData.id}`)
                        },
                    }),
                    ]}
                />
            </StyledContainer>
            <LeaveRequestExportModal open={modalState} setOpen={setmodalState} />
        </>

    );
};

export default observer(LeaveRequestList);