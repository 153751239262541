import { cl } from '@fullcalendar/core/internal-common';
import { saveAs } from 'file-saver';
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "semantic-ui-react";
import agent from '../../app/api/agents';
import DateRangeFilter from '../../app/common/table/DateRangeFilter';
import DateHelper from '../../app/helpers/dateHelper';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { LeaveRequestExportFilterValues } from '../../app/models/common/filterValues';
import { useStore } from '../../app/stores/store';


const dateHelper = new DateHelper();

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeaveRequestExportModal = ({ open, setOpen }: Props) => {
    const { userStore } = useStore();
    const { user } = userStore;
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const [isValid, setIsValid] = useState<boolean>(false);

    const [filterValues, setFilterValues] = useState<LeaveRequestExportFilterValues>({
        startDate: null,
        endDate: null
    });

    const downloadFile = async (query: LeaveRequestExportFilterValues) => {
        if (query.startDate && query.endDate) {
            setIsDownloading(true);

            query.startDate = dateHelper.formatToTimeZoneDateTime(query.startDate!, user?.timeZone!); 
            query.endDate = dateHelper.formatToTimeZoneDateTime(query.endDate!, user?.timeZone!); 

            await agent.LeaveRequests.download(query, { responseType: 'blob' })
                .then((response) => {
                    const startDate = dateHelper.formatToTimeZoneShortDateTime(query.startDate!, user?.timeZone!);
                    const endDate = dateHelper.formatToTimeZoneShortDateTime(query.startDate!, user?.timeZone!);

                    const filename = `Leave-Request-Report-${startDate}-${endDate}.xlsx`;
                    saveAs(response, filename);

                    setIsDownloading(false);
                })
                .catch((e) => {
                    setIsDownloading(false);
                    console.error(e);
                    toast.error('An error has occurred');
                });
        }
    }

    const handleDownload = async () => {
        await downloadFile(filterValues);

        handleDateRangeFilter();

        setOpen(false);
    }

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filterValues.startDate != startDate && filterValues.endDate != endDate) {
                setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
            }
            setIsValid(true);
        }

        else if (startDate == null || endDate == null) {
            setFilterValues({ ...filterValues, startDate: null, endDate: null });
            setIsValid(false);
        }
    }

    return (
        <>
            <Modal
                onClose={() => setOpen(false)}
                open={open}
                size={'mini'}
            >
                <Modal.Header>Leave Request Report</Modal.Header>
                <Modal.Content>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                            padding: '16px 16px',
                            borderBottom: '2px solid #d7e8f9'
                        }}
                    >
                        <DateRangeFilter
                            toolbarProps={null}
                            dateFilterLabel='Select Date Range'
                            onDateRangeFilter={handleDateRangeFilter}
                            dateRangeValues={[]}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!isValid}
                        content="Download"
                        onClick={handleDownload}
                        primary
                    />
                </Modal.Actions>
                {isDownloading && < LoadingComponent content="Downloading..." />}
            </Modal>
        </>
    )
}

export default observer(LeaveRequestExportModal);